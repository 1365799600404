import { startOfToday, add, sub } from "date-fns";

const exampledata = [   // this is allCourseData
    {   // this is courseData and tempCourseData
        name: "CISC 271",
        target: 90,
        // avg: 90.27,
        weightAchieved: 33.4,
        weightPossible: 37,
        archived: false,
        credits: 3,
        sections: [
            {   // this is a section
                name: "Assignments",
                drops: false,
                data: [
                    {   // this is an assessment
                        name: "Assignment 1",
                        mark: 93.75,    // they all got marks i just didnt put em here
                        dueDate: startOfToday(),
                        weightAchieved: 3.75,
                        weightPossible: 4,
                        dropped: false,
                        complete: false,
                        comments: ""
                    },
                    {   // this is an assessment
                        name: "Assignment 2",
                        dueDate: add(startOfToday(), { days: 1 }),
                        weightAchieved: 13.63,
                        mark: 90.87,
                        weightPossible: 15,
                        dropped: false,
                        complete: false,
                        comments: ""
                    },
                    {   // this is an assessment
                        name: "Assignment 3",
                        mark: 0,
                        dueDate: add(startOfToday(), { days: 2 }),
                        weightAchieved: null,
                        weightPossible: 15,
                        dropped: false,
                        complete: false,
                        comments: ""
                    }
                ]
            },
            {   // this is a section
                name: "Tests",
                drops: 1,
                data: [
                    {
                        name: "Test 1",
                        mark: 83,
                        dueDate: sub(startOfToday(), { days: 2 }),
                        weightAchieved: 7.47,
                        weightPossible: 9,
                        dropped: true,
                        complete: false
                    },
                    {
                        name: "Test 2",
                        mark: 95,
                        dueDate: startOfToday(),
                        weightAchieved: 8.55,
                        weightPossible: 9,
                        dropped: false,
                        complete: false
                    },
                ]
            }
        ]
    }
]

export default exampledata;

